
import { defineComponent } from "vue";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";
import DxButton from "devextreme-vue/button";
import { DxSelectBox } from "devextreme-vue";
import { DxCheckBox } from "devextreme-vue/check-box";
import api from "@/services/api";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    CatalogueSelectControl,
    DxButton,
    DxSelectBox,
    DxCheckBox,
  },
  props: { loading: { required: false } },
  methods: {
    async onFilterChange() {
      const { data } = await api.get(`/LoadingReport/GetFilterValues`, {
        params: {
          planId: this.model.planId,
        },
      });
      this.options = data as any;
    },
    async onPlanChange() {
      if (this.model.planId) {
        this.showOptions = true;
      } else {
        this.options = {};
        this.showOptions = false;
      }
      this.selectedOptions = {};
      await this.onFilterChange();
    },
    onButtonClick() {
      if (this.loading) this.onStop();
      else this.onReport;
    },
    onReport() {
      this.$emit("onReport", {
        planId: this.model.planId,
        orderNum: this.selectedOptions.orderNum,
        showCoords: this.selectedOptions.showCoords,
      });
    },
    onDownload() {
      this.$emit("onDownload", {
        planId: this.model.planId,
        orderNum: this.selectedOptions.orderNum,
        showCoords: this.selectedOptions.showCoords,
      });
    },
    onStop() {
      this.$emit("onStop");
    },
    setModel(model: any) {
      this.model = model;
      this.onReport();
    },
  },
  computed: {
    hasOrder() {
      return ability.can("view", "Modules.Order");
    },
  },
  data() {
    return {
      model: {},
      showOptions: false,
      options: {
        orders: null,
      },
      selectedOptions: {
        orderNum: null,
        showCoords: null
      },
    };
  },
});
